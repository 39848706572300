<template>
  <div class="literacy">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style>
.literacy {
  user-select: none;
}
</style>
